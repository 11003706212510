export const appHead = {"meta":[{"charset":"utf-8"},{"name":"format-detection","content":"telephone=no"},{"name":"viewport","content":"width=device-width,initial-scale=1.0,user-scalable=no,viewport-fit=cover"}],"link":[{"rel":"manifest","href":"/manifestPWA.json"}],"style":[],"script":[{"src":"https://www.googletagmanager.com/gtag/js?id=G-RKPWX0MT9M","async":true,"body":true},{"innerHTML":"window.dataLayer = window.dataLayer || []\n      function gtag() {\n        dataLayer.push(arguments)\n      }\n      gtag('js', new Date())\n      gtag('config', G-RKPWX0MT9M)","body":true},{"innerHTML":"var sc_project=13009376;\n          var sc_invisible=1;\n          var sc_security=\"95cef008\";\n        ","body":true},{"src":"https://www.statcounter.com/counter/counter.js","async":true,"body":true}],"noscript":[],"title":"Mastodon"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false